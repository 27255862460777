const AllWakaEn = [
    //春
    "Atarasiki Tosinohasimeni Kakusikoso Titosewokanete Tanosikiwotume",
    "Kasukanono Wakanatuminiya Sirotaheno Sotehurihahete Hitonoyukuramu",
    "Harukasumi Tanahikunoheno Wakananimo Naritmitesikana Hitomotumuyato",
    "Harukaseha Hukinamitariso Wakimokoka Katuranisutehu Awoyakinoito",
    "Sukanoneno Nakakiharuhimo Arumonowo Misikakarikeru Kimisokanasiki",
    "Harukiteso Hitomotohikeru Yamasatoha Hanakosoyatono Arusinarikere",
    "Yatorisite Harunoyamaheni Netaruyoha Yumenoutinimo Hanasotirikeru",
    "Miyamakino Sonokosuwetomo Miesarisi Sakurahahanani Araharenikeri",
    "Tokihanaru Hanamoyaaruto Yosinoyama Okunakuirite Nahotatunemimu",
    //夏
    "Yamatakami Sirayuhuhanani Otitakitu Takinokahutiha Miretoakanukamo",
    "Nukehatiru Nukanehamitaru Asihikino Yamayorioturu Takinosiratama",
    "Satukiyama Konositayamini Tomosuhiha Sikanotatitono Siruhenarikeri",
    "Ohokiumini Simamoaranakuni Unaharano Tayutahunamini Taterusirakumo",
    //秋
    "Akikinuto Kikiturukarani Wakayatono Wokinohakaseno Hukikaharuramu",
    "Akinokite Minisimukaseno Hukukoroha Ayasikihotoni Hitosokohisiki",
    "Sirotaheno Sotenowakareni Tuyuotite Minisimuirono Akikasesohuku",
    "Hikohosino Ahuyowotikaku Omohuyori Waresahesorani Nakamewososuru",
    "Akinotuki tunenikakuteru Mononaraha Yaminihurumiha Masirasaramasi",
    "Kasehakiyosi Tukihasayakesi Isatomoni Otoriakasamu Oinonakorini",
    "Utataneni Yoyahukenuramu Karakoromo Utukowetakaku Narimasarunari",
    "Akihukaku Narinikerasina Kirikirisu Yukanoatarini Kowekikoyunari",
    //冬
    "Asahorake Utinokahakiri Taetaeni Arawarewataru Sesenoasiroki",
    "Miyamaniha Ararehururasi Toyamanaru Masakinokatura Irotukinikeri",
    "Miyosinono Yamanosirayuki Tumorurasi Hurusatosamuku Narimasarunari",
    "Yosinoyama Minenosirayuki Humiwakete Irinisihitono Atosokohisiki",

    //離別＆羇旅
    "Hurusatomo Kohisikumonasi Tahinosora Miyakomotuino Sumikanaraneha",
    "Ohotukana Ikaninarumino Hatenaramu Yukuhemosiranu Tahinokanasisa",
    "Tahinesuru Arakihamaheno Naminootoni Itototatisohu Hitonoomokake",
    "Warekosoha Nihisimamoriyo Okinoumino Arakinamikase Kokorositehuke",
    "Omoikiya Hinanowakareni Otorohete Amanonawataki Isarisemutoha",
    "Wakurahani Tohuhitoaraha Sumanourani Mosihotaretutu Wahutokotaheyo",
    "Amasakaru Hinaniitutose Sumahitutu Miyakonotehuri Wasuraenikeri",
    "Unemeno Sotehukikahesu Asukakase Miyakowotohomi Itaturanihuku",
    "Muhatamano Imokakurokami Koyohimoya Wakanakitokoni Nahikiitenuramu",
    "Isarisuru Amanokatinooto Yukurakani Imohakokoroni Norinikerukamo",
    "Matohokuno Kumowinimiyuru Imokaheni Itukaitaramu Ayumeakakoma",

    //雑‐遠距離（歌枕）
    "Tohokaranu Husiminosatono Sekimoriha Kohatanomineni Kimisosuwekeru",
    "Kimiwookite Atasikokorowo Wakamotaha Suwenomatuyama Namimokoenamu",
    "Ahuminika Aritoihunaru Mikurikuru Hitokurusimeno Tukumaenonuma",
    "Koseyamano Turaturatuhaki Turaturani Mitutusinohana Kosenoharunowo",
    "Atumatino Kahoyakanumano Kahoyahana Tokisotomonaku Senasokohisiki",
    "Wakimokoni Wakakohiyukeha Tomosikumo Narahiworukamo Imotosenoyama",
    "Iwaminoya Takatunoyamano Konomayori Wakahurusotewo Imomituramuka",
    "Yorotuyoni Katarituketosi Konotakeni Hirehurikerasi Maturasayohime",
    "Wakaihoha Mihanoyamamoto Kohisikuha Tohurahikimase Sukitaterukato",

    //雑‐その他
    "Yamasatoni Tohikuruhitono Kotokusaha Konosumahikoso Urayamasikere",
    "Yononakaha Totemokakutemo Onasikoto Miyamowarayamo Hatesinakereha",
    "Tesusahino Hakanakimonowo Motiitete Urumanoitini Tatusowahisiki",
    "Wokusawoto Wokusasukewoto Sihohuneno Narahetemireha Wokusakatimeri",
    "Musasahiha Konuremotomuto Asihikino Yamanosatuwoni Ahinikerukamo",
    "Warehamoya Yasumikoetari Minahitono Ekatenisutohihu Yasumikoetari",
    "Hitonokono Oyaninariteso Wakaoyano Omohihaitoto Omohisiraruru",
    "Titihahaka Kasirakakinate Sakuarete Ihisiketohase Wasurekaneturu",
    "Hutahokami Asikehitonari Atamayuhi Wakasurutokini Sakimorinisasu",
    "Masurawono Yukutoihumitiso Ohorokani Omohiteyukuna Masurawonotomo",
    
    //雑-述懐
    "Ametutini Sukosiitaranu Masurawoto Omohisiwareya Wokokoromonaki",
    "Yononakani Masiranutoniha Aranetomo Hitoriasohiso Warehamasareru",
    "Arehaitohu Somukehasitahu Kasunaranu Mitokokorotono Nakasoyukasiki",
    "Kinimoarasu Kusanimoaranu Takenoyono Hasiniwakamiha Narinuheranari",
    "Kakirinaki Namitanotuyuni Musuharete Hitonosimotoha Naruniyaaruramu",
    "Hitotonaru kotokosokataki Yonaretomo Ahareikutahi Umarekinuramu",
    "Kasohureha Kurumawokakuru Yohahinite Nahokonowaniso Maharikinikeru",
    "Sakasamani Tosimoyukanamu Torimoahesu Sukuruyohahiya Tomonikaheruto",
    "Aruhanaku Nakihakasusohu Yononakani Ahareitumate Aramutosuramu",
    "Tuhiniyuku Mitimoimahano Tokinareya Hitusinoayumi Minisotikatuku",
    
    //哀傷
    "Minahitono Sirikahonisite Siranukana Kanarasusinuru Narahiaritoha",
    "Tuneyorimo Hakanakikorono Yuhukureha Nakunaruhitoso Kasoherarekeru",    
    "Tomosihino Kakenikakayohu Utusemino Imokawemahisi Omokakenimiyu",
    "Wakimokoka Nekutarekamiwo Sarusahano Ikenotamamoto Mirusokanasiki",
    "Utusomino Hitonaruwareya Asuyoriha Hutakamiyamawo Irosetoakamimu",
    "Netemomiyu Netemomiekeri Ohokataha Utuseminoyoso Yumenihaarikeru",
    
    //釈経・神祇
    "Itunoyoni Nakakinehurino Yumesamete Otorokukotono Aramutosuramu",
    "Yumesamemu Sonoakatukiwo Matuhotono Yamiwomoterase Norinotomosihi",
    "Yononakaha Minahotokenari Osinahete Iturenomonoto Wakusohakanaki",
    "Tamakatura Minaranukiniha Tihayahuru Kamisotukutoihu Naranukikotoni",
    "Simenoutini Kinenootokoso Kikoyunare Ikanarukamino Tukunikaaruramu",
    "Inariyama Mitunotamakaki Utitataki Wakanekikotowo Kamimokotaheyo",
    "Kamimoukesu Hitomoturenasi Ikutahimo Uramitokoroya Minikaheruramu",
    "Nahotanome Simetikaharano Sasemokusa Wakayononakani Aramukakiriha",
    
    //恋
    "Matasiranu Hitowohasimete Kohurukana Omohukokoroyo Mitisiruheseyo",
    "Omohiyaru Sakahiharukani Nariyasuru Matohuyumetini Ahuhitononaki",
    "Kasukanono Yukimawowakete Ohiitekuru Kusanohatukani Miesikimihamo",
    "Misumoarasu Mimosenuhitono Kohisikuha Ayanakukehuya Nakamekurasamu",
    "Iseoumini Turisuruamano Ukenareya Kokorohitotuwo Satamekaneturu",
    "Amakumono Haruharumiesi Mineyorimo Takakusokimiwo  Omohisometesi",
    "Ikemituno Ihiiturukotono Katakereha Mikomorinakara Tosisohenikeru",
    "Ihanumaha Sitahahuasino Newosikemi Himanakikohiwo Hitosirurameya",
    "Nanihameno Sukumotakuhino Sitakokare Uhehaturenaki Wakaminarikeri",
    "Sitaninomi Sinohumotisuri Kurusikiha Kokoronoutino Mitarenarikeri",
    "Ihatenomi Omohukokorowo Siruhitoha Ariyanasiyato Tarenitohamasi",
    "Yosoninomi Miteyahakohimu Kurenawino Suwetumuhanano Ironiitesuha",
    "Kurenawino Ironihaitesi Kakurenuno Sitanikayohite Kohihasinutomo",
    "Ikinowoni Omohehakurusi Tamanowono Taetemitarena Sirahasirutomo",
    "Omohinisi Amarinisikaha Katoniitete Wakakoihusuwo Hitomikemukamo",
    "Kohihaimaha Arasitowareha Omoheruwo Itukunokohiso Tukamikakareru",
    "Iwahosura Yukitohoruheki Masurawomo Kohitoihukotoha Notikuhinikeri",
    "Kohitoiheha Onasinanikoso Omohurame Ikatewakamiwo Hitonisirasemu",
    "Hitosiresu Omohukokoroha Harukasumi Tatiitetekimika Menimomienamu",
    "Woriworiha Omohukokoromo Miyuramuwo Turenayahitono Sirasukahonaru",
    "Ohonaraha Takamimutokamo Nuhatamano Wakakurokamiwo Nahiketeworamu",
    "Kokoronomi Kayohunakatoha Narinureto Ukisekimorino Tayumuyomonasi",
    "Hitosirenu Mihaisoketomo Tosiwohete Natokoekataki Ahusakanoseki",
    "Tihayahuru Kaminoikakimo Koenuhesi Imahawakanano Osikekumonasi",
    "Sinonomeno Hokarahokarato Akeyukeha Onokakinukinu Narusokanasiki",
    "Okuriteha Kaheretoomohisi Tamasihino Yukisasurahite Kesahanakikana",
    "Tokinomamo Kokorohasorani Narumonowo Ikatesukusisi Mukasinaruramu",
    "Ahimiteha Simasikukohiha Nakimukato Omohetoiyoyo Kohimasarikeri",
    "Tamakatuma Ahamutoihuha Tarenaruka Aherutokisahe Omokakusisuru",
    "Imasarani Naniwokaomohamu Utinahiki Kokorohakimini Yorinisimonowo",
    "Ihahasino Yorunotikirimo Taenuhesi Akuruwahisiki Katurakinokami",
    "Itukatani Tatikakuretutu Miyototeka Omohikumanaku Hitononariyuku",
    "Minahitowo Onasikokoroni Nasihatete Omohuomohanu Nakaramasikaha",
    "Ituharito Omohinakaraya Tikiruramu Sirahayahitono Sitanokokorowo",
    "Kasunarate Kokoronimiwoha Makaseneto Minisitakahuha Kokoronarikeri",
    "Kasunaranu Miwoutikahano Hasihasito Iharenakaramo Kohiwatarukana",
    "Uramitemo Mikosoturakere Karakoromo Kiteitaturani Kahesutoomoheha",
    "Haruharuto Nonakanimiyuru Wasuremitu Taemataemawo Nakekukorokana",
    "Ahukotono Nakekinomotowo Tatunereha Hitorineyoriso Ohihasimekeru",
    "Omohikiya Ahiminuhotono Tositukiwo Kasohuhakarini Naramumonotoha",
    "Ikahonoya Ikahononumano Ikanisite Kohisikihitowo Imahitomemimu",
    "Hitokatani Omohitaenisi Yononakawo Ikakahasuheki Situnowotamaki",
    "Yosoninomi Kikamasimonowo Otohakaha Watarutonasini Minaresomekemu",
    "Kimikohuru Namitanotokoni Mitinureha Miwotukusitoso Warehanarinuru",
    "Warehakari Wasururuyonaku Nakeketoya Ukiomokakewo Nahonokosuramu",
    "Ukinakara Hitowohaesimo Wasureneha Katuuramitutu Nahosokohisiki",
    "Turakereto Hitonihaihasu Ihamikata Uramisohukaki Kokorohitotuni",
    "Kotonohani Itesiuramiha Tukihatete Kokoronikomuru Usaninarinuru",
    "Itumateka Hitonoturasani Nakarahemu Nahotamanowono Omohiyowarate",
    "Itukatani Yukikakurenamu Yononakani Minoarehakoso Hitomoturakere",
    "Ikanisemu Turakikakiriwo Mitemomata Nahositaharuru Kokoroyohasawo",
    "Turakutomo Nahoutusemino Miwokahete Notinoyomateya Hitowokohimasi",
    "Ikuhakumo Ikerasiinotiwo Kohitutuso Warehaikituku Hitonisiraesu",
    "Kohisitoha Takanatukekemu Kotonaramu Sinutosotatani Ihuhekarikeru",
    "Kohisinaha Ukaremutamayo Sihasitani Wakaomohuhitono Tumanitotomare",
    "Nakekasina Omohehahitoni Turakarisi Konoyonakarano Mukuhinarikeri",
    
    //賀
    "Hatuharuno Hatunenokehuno Tamahahaki Tenitorukarani Yurakutamanowo",
    "Sakakihawo Tenitorimotite Inorituru Kaminoyoyorimo Hisasikaranamu",
    "Sakakihano Kawokakuhasimi Tomekureha Yasoutihitoso Matowiserikeru",
    "Makimokuno Anasinoyamano Yamahitoto Hitomomirukani Yamakaturaseyo",
    "Ototakaki Tutuminoyamano Utihahete Tanosikimiyoto Narusouresiki",
    "Kumorinaki Tamanoutenani Nohoriteso Harukanaruyono Kotomomiekeru",
    "Kahanoheno Yutuihamurani Kusamusasu Tunenimokamona Tokowotomenite",
    "Inisiheni Arikiarasuha Siranetomo Titosenotamesi Kiminihasimemu",
    "Titosemate Kakirerumatumo Kehuyoriha Kiminihikarete Yorotuyoyahemu",
    "Sikisimaya Yamatosimanemo Kamiyoyori Kimikatametoya Katameokikemu",
    "Sikisimano Yamatonokunini Hitohutari Aritosiomohaha Nanikanakekamu",
    "Hinimitahi Orokanarumiwo Kaherimite Tukahurumitimo Wakakiminotame",
    "Yotunoumi Namimowosamaru Sirusitote Mitunotakarawo Minisotutahuru",

];

export default AllWakaEn;


