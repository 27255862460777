const AllWakaJp = [
    //春
    "あたらしき としのはしめに かくしこそ ちとせをかねて たのしきをつめ",
    "かすかのの わかなつみにや しろたへの そてふりはへて ひとのゆくらむ",
    "はるかすみ たなひくのへの わかなにも なりみてしかな ひともつむやと",
    "はるかせは ふきなみたりそ わきもこか かつらにすてふ あをやきのいと",
    "すかのねの なかきはるひも あるものを みしかかりける きみそかなしき",
    "はるきてそ ひともとひける やまさとは はなこそやとの あるしなりけれ",
    "やとりして はるのやまへに ねたるよは ゆめのうちにも はなそちりける",
    "みやまきの そのこすゑとも みえさりし さくらははなに あらはれにけり",
    "ときはなる はなもやあると よしのやま おくなくいりて なほたつねみむ",
    //夏
    "やまたかみ しらゆふはなに おちたきつ たきのかふちは みれとあかぬかも",
    "ぬけはちる ぬかねはみたる あしひきの やまよりおつる たきのしらたま",
    "さつきやま このしたやみに ともすひは しかのたちとの しるへなりけり",
    "おほきうみに しまもあらなくに うなはらの たゆたふなみに たてるしらくも",
    //秋
    "あききぬと ききつるからに わかやとの をきのはかせの ふきかはるらむ",
    "あきのきて みにしむかせの ふくころは あやしきほとに ひとそこひしき",
    "しろたへの そてのわかれに つゆおちて みにしむいろの あきかせそふく",
    "ひこほしの あふよをちかく おもふより われさへそらに なかめをそする",
    "あきのつき つねにかくてる ものならは やみにふるみは ましらさらまし",
    "かせはきよし つきはさやけし いさともに おとりあかさむ おいのなこりに",
    "うたたねに よやふけぬらむ からころも うつこゑたかく なりまさるなり",
    "あきふかく なりにけらしな きりきりす ゆかのあたりに こゑきこゆなり",
    //冬
    "あさほらけ うちのかはきり たえたえに あらはれわたる せせのあしろき",
    "みやまには あられふるらし とやまなる まさきのかつら いろつきにけり",
    "みよしのの やまのしらゆき つもるらし ふるさとさむく なりまさるなり",
    "よしのやま みねのしらゆき ふみわけて いりにしひとの あとそこひしき",

    //離別＆羇旅
    "ふるさとも こひしくもなし たひのそら みやこもついの すみかならねは",
    "おほつかな いかになるみの はてならむ ゆくへもしらぬ たひのかなしさ",
    "たひねする あらきはまへの なみのおとに いととたちそふ ひとのおもかけ",
    "われこそは にひしまもりよ おきのうみの あらきなみかせ こころしてふけ",
    "おもひきや ひなのわかれに おとろへて あまのなはたき いさりせむとは",
    "わくらはに とふひとあらは すまのうらに もしほたれつつ わふとこたへよ",
    "あまさかる ひなにいつとせ すまひつつ みやこのてふり わすらえにけり",
    "うねめの そてふきかへす あすかかせ みやこをとほみ いたつらにふく",
    "むはたまの いもかくろかみ こよひもや わかなきとこに なひきいてぬらむ",
    "いさりする あまのかのちおと ゆくらかに いもはこころに のりにけるかも",
    "まとほくの くもゐにみゆる いもかへに いつかいたらむ あゆめあかこま",

    //雑‐遠距離（歌枕）
    "とほからぬ ふしみのさとの せきもりは こはたのみねに きみそすゑける",
    "きみをおきて あたしこころを わかもたは すゑのまつやま なみもこえなむ",
    "あふみにか ありといふなる みくりくる ひとくるしめの つくまえのぬま",
    "こせやまの つらつらつはき つらつらに みつつしのはな こせのはるのを",
    "あつまちの かほやかぬまの かほやはな ときそともなく せなそこひしき",
    "わきもこに わかこひゆけは ともしくも ならひをるかも いもとせのやま",
    "いはみのや たかつのやまの このまより わかふるそてを いもみつらむか",
    "よろつよに かたりつけとし このたけに ひれふりけらし まつらさよひめ",
    "わかいほは みわのやまもと こひしくは とふらひきませ すきたてるかと",

    //雑‐その他
    "やまさとに とひくるひとの ことくさは このすまひこそ うらやましけれ",
    "よのなかは とてもかくても おなしこと みやもわらやも はてしなけれは",
    "てすさひの はかなきものを もちいてて うるまのいちに たつそわひしき",
    "をくさをと をくさすけをと しほふねの ならへてみれは をくさかちめり",
    "むささひは こぬれもとむと あしひきの やまのさつをに あひにけるかも",
    "われはもや やすみこえたり みなひとの えかてにすとふ やすみこえたり",
    "ひとのこの おやになりてそ わかおやの おもひはいとと おもひしらるる",
    "ちちははか かしらかきなて さくあれて いひしけとはせ わすれかねつる",
    "ふたほかみ あしけひとなり あたゆまひ わかするときに さきもりにさす",
    "ますらをの ゆくといふみちそ おほろかに おもひてゆくな ますらをのとも",
    
    //雑-述懐
    "あめつちに すこしいたらぬ ますらをと おもひしわれや をこころもなき",
    "よのなかに ましらぬとには あらねとも ひとりあそひそ われそまされる",
    "あれはいとふ そむけはしたふ かすならぬ みとこころとの なかそゆかしき",
    "きにもあらす くさにもあらぬ たけのよの はしにわかみは なりぬへらなり",
    "かきりなき なみたのつゆに むすはれて ひとのしもとは なるにやあるらむ",
    "ひととなる ことこそかたき よなれとも あはれいくたひ うまれきぬらむ",
    "かそふれは くるまをかくる よはひにて なほこのわにそ まはりきにける",
    "さかさまに としもゆかなむ とりもあへす すくるよはひや ともにかへると",
    "あるはなく なきはかすそふ よのなかに あはれいつまて あらむとすらむ",
    "つひにゆく みちもいまはの ときなれや ひつしのあゆみ みにそちかつく",
    
    //哀傷
    "みなひとの しりかほにして しらぬかな かならすしぬる ならひありとは",
    "つねよりも はかなきころの ゆふくれは なくなるひとそ かそへられける",    
    "ともしひの かけにかかよふ うつせみの いもかゑまひし おもかけにみゆ",
    "わきもこか ねくたれかみを さるさはの いけのたまもと みるそかなしき",
    "うつそみの ひとなるわれや あすよりは ふたかみやまを いろせとあかみむ",
    "ねてもみゆ ねてもみえけり おほかたは うつせみのよそ ゆめにはありける",
    
    //釈経・神祇
    "いつのよに なかきねふりの ゆめさめて おとろくことの あらむとすらむ",
    "ゆめさめむ そのあかつきを まつほとの やみをもてらせ のりのともしひ",
    "よのなかは みなほとけなり おしなへて いつれのものと わくそはかなき",
    "たまかつら みならぬきには ちはやふる かみそつくといふ ならぬきことに",
    "しめのうちに きねのおとこそ きこゆなれ いかなるかみの つくにかあるらむ",
    "いなりやま みつのたまかき うちたたき わかねきことを かみもこたへよ",
    "かみもうけす ひともつれなし いくたひも うらみところや みにかへるらむ",
    "なほたのめ しめちかはらの させもくさ わかよのなかに あらむかきりは",
    
    //恋
    "またしらぬ ひとをはしめて こふるかな おもふこころよ みちしるへせよ",
    "おもひやる さかひはるかに なりやする まとふゆめちに あふひとのなき",
    "かすかのの ゆきまをわけて おひいてくる くさのはつかに みえしきみはも",
    "みすもあらす みもせぬひとの こひしくは あやなくけふや なかめくらさむ",
    "いせのうみに つりするあまの うけなれや こころひとつを さためかねつる",
    "あまくもの はるはるみえし みねよりも たかくそきみを おもひそめてし",
    "いけみつの いひいつることの かたけれは みこもりなから としそへにける",
    "いはぬまは したはふあしの ねをしけみ ひまなきこひを ひとしるらめや",
    "なにはめの すくもたくひの したこかれ うへはつれなき わかみなりけり",
    "したにのみ しのふもちすり くるしきは こころのうちの みたれなりけり",
    "いはてのみ おもふこころを しるひとは ありやなしやと たれにとはまし",
    "よそにのみ みてやはこひむ くれなゐの すゑつむはなの いろにいてすは",
    "くれなゐの いろにはいてし かくれぬの したにかよひて こひはしぬとも",
    "いきのをに おもへはくるし たまのをの たえてみたれな しらはしるとも",
    "おもひにし あまりにしかは かとにいてて わかこいふすを ひとみけむかも",
    "こひはいまは あらしとわれは おもへるを いつくのこひそ つかみかかれる",
    "いはほすら ゆきとほるへき ますらをも こひといふことは のちくいにけり",
    "こひといへは おなしなにこそ おもふらめ いかてわかみを ひとにしらせむ",
    "ひとしれす おもふこころは はるかすみ たちいててきみか めにもみえなむ",
    "をりをりは おもふこころも みゆらむを つれなやひとの しらすかほなる",
    "おほならは たかみむとかも ぬはたまの わかくろかみを なひけてをらむ",
    "こころのみ かよふなかとは なりぬれと うきせきもりの たゆむよもなし",
    "ひとしれぬ みはいそけとも としをへて なとこえかたき あふさかのせき",
    "ちはやふる かみのいかきも こえぬへし いまはわかなの をしけくもなし",
    "しののめの ほからほからと あけゆけは おのかきぬきぬ なるそかなしき",
    "おくりては かへれとおもひし たましひの ゆきさすらひて けさはなきかな",
    "ときのまも こころはそらに なるものを いかてすくしし むかしなるらむ",
    "あひみては しましくこひは なきむかと おもへといよよ こひまさりけり",
    "たまかつま あはむといふは たれなるか あへるときさへ おもかくしする",
    "いまさらに なにをかおもはむ うちなひき こころはきみに よりにしものを",
    "いははしの よるのちきりも たえぬへし あくるわひしき かつらきのかみ",
    "いつかたに たちかくれつつ みよとてか おもひくまなく ひとのなりゆく",
    "みなひとを おなしこころに なしはてて おもふおもはぬ なからましかは",
    "いつはりと おもひなからや ちきるらむ しらはやひとの したのこころを",
    "かすならて こころにみをは まかせねと みにしたかふは こころなりけり",
    "かすならぬ みをうちかはの はしはしと いはれなからも こひわたるかな",
    "うらみても みこそつらけれ からころも きていたつらに かへすとおもへは",
    "はるはると のなかにみゆる わすれみつ たえまたえまを なけくころかな",
    "あふことの なけきのもとを たつぬれは ひとりねよりそ おひはしめける",
    "おもひきや あひみぬほとの としつきを かそふはかりに ならむものとは",
    "いかほのや いかほのぬまの いかにして こひしきひとを いまひとめみむ",
    "ひとかたに おもひたえにし よのなかを いかかはすへき しつのをたまき",
    "よそにのみ きかましものを おとはかは わたるとなしに みなれそめけむ",
    "きみこふる なみたのとこに みちぬれは みをつくしとそ われはなりぬる",
    "われはかり わするるよなく なけけとや うきおもかけを なほのこすらむ",
    "うきなから ひとをはえしも わすれねは かつうらみつつ なほそこひしき",
    "つらけれと ひとにはいはす いはみかた うらみそふかき こころひとつに",
    "ことのはに いてしうらみは つきはてて こころにこむる うさになりぬる",
    "いつまてか ひとのつらさに なからへむ なほたまのをの おもひよわらて",
    "いつかたに ゆきかくれなむ よのなかに みのあれはこそ ひともつらけれ",
    "いかにせむ つらきかきりを みてもまた なほしたはるる こころよはさを",
    "つらくとも なほうつせみの みをかへて のちのよまてや ひとをこひまし",
    "いくはくも いけらしいのちを こひつつそ われはいきつく ひとにしらえす",
    "こひしとは たかなつけけむ ことならむ しぬとそたたに いふへかりける",
    "こひしなは うかれむたまよ しはしたに わかおもふひとの つまにととまれ",
    "なけかしな おもへはひとに つらかりし このよなからの むくいなりけり",
    
    //賀
    "はつはるの はつねのけふの たまははき てにとるからに ゆらくたまのを",
    "さかきはを てにとりもちて いのりつる かみのよよりも ひさしからなむ",
    "さかきはの かをかくはしみ とめくれは やそうちひとそ まとゐせりける",
    "まきもくの あなしのやまの やまひとと ひともみるかに やまかつらせよ",
    "おとたかき つつみのやまの うちはへて たのしきみよと なるそうれしき",
    "くもりなき たまのうてなに のほりてそ はるかなるよの こともみえける",
    "かはのへの ゆついはむらに くさむさす つねにもかもな とこをとめにて",
    "いにしへに ありきあらすは しらねとも ちとせのためし きみにはしめむ",
    "ちとせまて かきれるまつも けふよりは きみにひかれて よろつよやへむ",
    "しきしまや やまとしまねも かみよより きみかためとや かためおきけむ",
    "しきしまの やまとのくにに ひとふたり ありとしおもはは なにかなけかむ",
    "ひにみたひ おろかなるみを かへりみて つかふるみちも わかきみのため",
    "よつのうみ なみもをさまる しるしとて みつのたからを みにそつたふる",

];

export default AllWakaJp;


