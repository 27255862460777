import React from "react"

//CSS
import { div } from "./topAnm.module.css"

const TopAnm = (props) => {
  return (
    <div

      className={div}
    >
      <div>
        <iframe
          title="animated cartoon"
          //src="https://www.drawinghell.com/topAnm/_topAnm.html"
          src={props.link}
          style={{
            width: `${(props.width / props.height) > (512 / 758) ?
              Math.min(Math.min(props.height, 758) * (512 / 758), 512)
              : Math.min(Math.min(props.height, 758) * (512 / 758), Math.min(props.width, 512))
              || 512}px`,
            height: `${(props.width / props.height) > (512 / 758) ?
              Math.min(props.height * 0.9, 758)
              : Math.min(props.width * (758 / 512) * 0.9, 758)
               || 758}px`,
            borderWidth: `0px`
          }}
          id="iFrame"
        ></iframe>

      </div>
    </div>
  )
}

export default TopAnm;