const AllPagesURL = [
    //"top",
    
    //春
    "M05_058",//あたらしきとしのはしめにかくしこそちとせをかねてたのしきをつめ◎
    "M03_120",//かすかののわかなつみにやしろたへのそてふりはへてひとのゆくらむ◎
    "M05_143",//はるかすみたなひくのへのわかなにもなりみてしかなひともつむやと◎
    "M07_137",//はるかせはふきなみたりそわきもこかかつらにすてふあをやきのいと◎
    "M08_157",//すかのねのなかきはるひもあるものをみしかかりけるきみそかなしき◎
    "M08_020",//はるきてそひともとひけるやまさとははなこそやどのあるしなりけれ◎
    "M02_090",//やとりしてはるのやまへにねたるよはゆめのうちにもはなそちりける◎
    "M08_016",//みやまきのそのこすゑともみえさりしさくらははなにあらはれにけり◎i
    "M02_177",//ときはなるはなもやあるとよしのやまおくなくいりてなほたつねみむ◎
    //夏
    "GM03_100",//やまたかみしらゆふはなにおちたきつたきのかふちはみれとあかぬかも◎
    "M03_034",//ぬけはちるぬかねはみたるあしひきのやまよりおつるたきのしらたま◎
    "M08_022",//さつきやまこのしたやみにともすひはしかのたちとのしるへなりけり◎
    "M03_036",//おほきうみにしまもあらなくにうなはらのたゆたふなみにたてるしらくも◎
    //秋
    "M02_009",//あききぬとききつるからにわかやとのをきのはかせのふきかはるらむ◎
    "M07_107",//あきのきてみにしむかせのふくころはあやしきほとにひとそこひしき◎
    "M07_031",//しろたへのそてのわかれにつゆおちてみにしむいろのあきかせそふく◎
    "M07_023",//ひこほしのあふよをちかくおもふよりわれさへそらになかめをそする◎
    "M06_017",//あきのつきつねにかくてるものならはやみにふるみはましらさらまし◎
    "M08_127",//かせはきよしつきはさやけしいさともにおとりあかさむおいのなこりに◎
    "M02_067",//うたたねによやふけぬらむからころもうつこゑたかくなりまさるなり◎
    "M07_077",//あきふかくなりにけらしなきりきりすゆかのあたりにこゑきこゆなり◎→除外次候補？
    //冬
    "M03_110",//あさほらけうちのかはきりたえたえにあらはれわたるせせのあしろき◎
    "M09_134",//みやまにはあられふるらしとやまなるまさきのかつらいろつきにけり◎
    "M02_109",//みよしののやまのしらゆきつもるらしふるさとさむくなりまさるなり◎
    "M07_125",//よしのやまみねのしらゆきふみわけていりにしひとのあとそこひしき◎

    //離別＆羇旅
    "M04_171",//ふるさともこひしくもなしたひのそらみやこもついのすみかならねは◎→スクロール終了時に山が中途で消えることがある?
    "M02_052",//おほつかないかになるみのはてならむゆくへもしらぬたひのかなしさ◎→重い（一応対処済）
    "M07_034",//たひねするあらきはまへのなみのおとにいととたちそふひとのおもかけ◎
    "M03_082",//われこそはにひしまもりよおきのうみのあらきなみかせこころしてふけ◎
    "M06_115",//おもひきやひなのわかれにおとろへてあまのなはたきいさりせむとは◎
    "M02_175",//わくらはにとふひとあらはすまのうらにもしほたれつつわふとこたへよ◎
    "GM03_013",//あまさかるひなにいつとせすまひつつみやこのてふりわすらえにけり◎
    "M02_139",//うねめのそてふきかへすあすかかせみやこをとほみいたつらにふく◎
    "M02_134",//むはたまのいもかくろかみこよひもやわかなきとこになひきいてぬらむ◎
    "M03_116",//いさりするあまのかのちおとゆくらかにいもはこころにのりにけるかも◎
    "GM03_067",//まとほくのくもゐにみゆるいもかへにいつかいたらむあゆめあかこま◎

    //雑‐遠距離（歌枕）
    "M05_005",//とほからぬふしみのさとのせきもりはこはたのみねにきみそすゑける◎
    "M06_152",//きみをおきてあたしこころをわかもたはすゑのまつやまなみもこえなむ◎
    "M05_081",//あふみにかありといふなるみくりくるひとくるしめのつくまえのぬま◎
    "M06_155",//こせやまのつらつらつはきつらつらにみつつしのはなこせのはるのを◎
    "GM01_105",//あつまちのかほやかぬまのかほやはなときそともなくせなそこひしき◎
    "M06_103",//わきもこにわかこひゆけはともしくもならひをるかもいもとせのやま◎
    "M06_022",//いはみのやたかつのやまのこのまよりわかふるそてをいもみつらむか◎
    "M06_142",//よろつよにかたりつけとしこのたけにひれふりけらしまつらさよひめ◎
    "M02_012",//わかいほはみわのやまもとこひしくはとふらひきませすきたてるかと◎→*firefoxのみcompositeOperation= "lighter" が効かない

    //雑‐その他
    "M05_179",//やまさとにとひくるひとのことくさはこのすまひこそうらやましけれ◎
    "M08_104",//よのなかはとてもかくてもおなしことみやもわらやもはてしなけれは◎
    "M02_099",//てすさひのはかなきものをもちいててうるまのいちにたつそわひしき◎
    "M07_039",//をくさをとをくさすけをとしほふねのならへてみれはをくさかちめり◎→画面非表示時に首元が斜めになるときがある？(一応対応済)
    "M08_081",//むささひはこぬれもとむとあしひきのやまのさつをにあひにけるかも◎
    "M08_114",//われはもややすみこえたりみなひとのえかてにすとふやすみこえたり◎
    "M04_035",//ひとのこのおやになりてそわかおやのおもひはいととおもひしらるる◎
    "GM03_020",//ちちははかかしらかきなてさくあれていひしけとはせわすれかねつる◎
    "M02_174",//ふたほかみあしけひとなりあたゆまひわかするときにさきもりにさす◎
    "M02_019",//ますらをのゆくといふみちそおほろかにおもひてゆくなますらをのとも◎
    
    //雑-述懐
    "M07_098",//あめつちにすこしいたらぬますらをとおもひしわれやをこころもなき◎
    "M02_127",//よのなかにましらぬとにはあらねともひとりあそひそわれそまされる◎
    "M04_053",//あれはいとふそむけはしたふかすならぬみとこころとのなかそゆかしき◎
    "M06_111",//きにもあらすくさにもあらぬたけのよのはしにわかみはなりぬへらなり◎
    "M03_118",//かきりなきなみたのつゆにむすはれてひとのしもとはなるにやあるらむ◎
    "M08_146",//ひととなることこそかたきよなれともあはれいくたひうまれきぬらむ◎
    "M06_166",//かそふれはくるまをかくるよはひにてなほこのわにそまはりきにける◎
    "M06_016",//さかさまにとしもゆかなむとりもあへすすくるよはひやともにかへると◎
    "M07_122",//あるはなくなきはかすそふよのなかにあはれいつまてあらむとすらむ◎
    "M06_026",//つひにゆくみちもいまはのときなれやひつしのあゆみみにそちかつく◎
    
    //哀傷
    "M02_036",//みなひとのしりかほにしてしらぬかなかならすしぬるならひありとは◎
    "M06_042",//つねよりもはかなきころのゆふくれはなくなるひとそかそへられける◎    
    "M02_146",//ともしひのかけにかかよふうつせみのいもかゑまひしおもかけにみゆ◎
    "M07_136",//わきもこかねくたれかみをさるさはのいけのたまもとみるそかなしき◎
    "M08_036",//うつそみのひとなるわれやあすよりはふたかみやまをいろせとあかみむ◎
    "M02_080",//ねてもみゆねてもみえけりおほかたはうつせみのよそゆめにはありける◎
    
    //釈経・神祇
    "M02_059",//いつのよになかきねふりのゆめさめておとろくことのあらむとすらむ◎
    "M01_044",//ゆめさめむそのあかつきをまつほとのやみをもてらせのりのともしひ◎
    "M02_153",//よのなかはみなほとけなりおしなへていつれのものとわくそはかなき◎
    "M08_137",//たまかつらみならぬきにはちはやふるかみそつくといふならぬきことに◎
    "M06_003",//しめのうちにきねのおとこそきこゆなれいかなるかみのつくにかあるらむ◎
    "M07_026",//いなりやまみつのたまかきうちたたきわかねきことをかみもこたへよ◎
    "M08_021",//かみもうけすひともつれなしいくたひもうらみところやみにかへるらむ◎
    "M08_019",//なほたのめしめちかはらのさせもくさわかよのなかにあらむかきりは◎
    
    //恋
    "M02_115",//またしらぬひとをはしめてこふるかなおもふこころよみちしるへせよ◎
    "M06_157",//おもひやるさかひはるかになりやするまとふゆめちにあふひとのなき◎
    "M07_021",//かすかののゆきまをわけておひいてくるくさのはつかにみえしきみはも◎
    "M01_042",//みすもあらすみもせぬひとのこひしくはあやなくけふやなかめくらさむ◎
    "M06_161",//いせのうみにつりするあまのうけなれやこころひとつをさためかねつる◎
    "M06_114",//あまくものはるはるみえしみねよりもたかくそきみをおもひそめてし◎
    "M06_035",//いけみつのいひいつることのかたけれはみこもりなからとしそへにける◎→やや重
    "M02_128",//いはぬまはしたはふあしのねをしけみひまなきこひをひとしるらめや◎
    "M07_008",//なにはめのすくもたくひのしたこかれうへはつれなきわかみなりけり◎
    "M07_180",//したにのみしのふもちすりくるしきはこころのうちのみたれなりけり◎
    "M03_029",//いはてのみおもふこころをしるひとはありやなしやとたれにとはまし◎
    "M02_129",//よそにのみみてやはこひむくれなゐのすゑつむはなのいろにいてすは◎
    "M06_096",//くれなゐのいろにはいてしかくれぬのしたにかよひてこひはしぬとも◎
    "M05_020",//いきのをにおもへはくるしたまのをのたえてみたれなしらはしるとも◎
    "M06_049",//おもひにしあまりにしかはかとにいててわがこいふすをひとみけむかも◎→重い
    "M02_068",//こひはいまはあらしとわれはおもへるをいつくのこひそつかみかかれる◎
    "M04_056",//いはほすらゆきとほるへきますらをもこひといふことはのちくいにけり◎
    "M07_035",//こひといへはおなしなにこそおもふらめいかてわかみをひとにしらせむ◎
    "M06_013",//ひとしれすおもふこころははるかすみたちいててきみかめにもみえなむ◎
    "M04_084",//をりをりはおもふこころもみゆらむをつれなやひとのしらすかほなる◎
    "M06_023",//おほならはたかみむとかもぬはたまのわかくろかみをなひけてをらむ◎
    "GM01_160",//こころのみかよふなかとはなりぬれとうきせきもりのたゆむよもなし◎
    "M04_007",//ひとしれぬみはいそけともとしをへてなとこえかたきあふさかのせき◎
    "M06_002",//ちはやふるかみのいかきもこえぬへしいまはわかなのをしけくもなし◎
    "M06_169",//しののめのほからほからとあけゆけはおのかきぬきぬなるそかなしき◎
    "M08_130",//おくりてはかへれとおもひしたましひのゆきさすらひてけさはなきかな◎
    "M08_005",//ときのまもこころはそらになるものをいかてすくししむかしなるらむ◎
    "M08_125",//あひみてはしましくこひはなきむかとおもへといよよこひまさりけり◎
    "M02_063",//たまかつまあはむといふはたれなるかあへるときさへおもかくしする◎
    "GM03_032",//いまさらになにをかおもはむうちなひきこころはきみによりにしものを◎
    "M08_128",//いははしのよるのちきりもたえぬべしあくるわひしきかつらきのかみ◎
    "M07_138",//いつかたにたちかくれつつみよとてかおもひくまなくひとのなりゆく◎
    "M02_011",//みなひとをおなしこころになしはてておもふおもはぬなからましかは◎
    "M04_083",//いつはりとおもひなからやちきるらむしらはやひとのしたのこころを◎
    "M03_079",//かすならてこころにみをはまかせねとみにしたかふはこころなりけり◎
    "M06_117",//かすならぬみをうちかはのはしはしといはれなからもこひわたるかな◎
    "M02_136",//うらみてもみこそつらけれからころもきていたつらにかへすとおもへは◎
    "M06_146",//はるはるとのなかにみゆるわすれみつたえまたえまをなけくころかな◎
    "GM02_165",//あふことのなけきのもとをたつぬれはひとりねよりそおひはしめける◎
    "M08_126",//おもひきやあひみぬほとのとしつきをかそふはかりにならむものとは◎
    "M08_107",//いかほのやいかほのぬまのいかにしてこひしきひとをいまひとめみむ◎
    "M07_027",//ひとかたにおもひたえにしよのなかをいかかはすへきしつのをたまき◎
    "M06_045",//よそにのみきかましものをおとはかはわたるとなしにみなれそめけむ◎
    "M02_162",//きみこふるなみたのとこにみちぬれはみをつくしとそわれはなりぬる◎
    "M03_014",//われはかりわするるよなくなけけとやうきおもかけをなほのこすらむ◎
    "M03_100",//うきなからひとをはえしもわすれねはかつうらみつつなほそこひしき◎
    "M06_147",//つらけれとひとにはいはすいはみかたうらみそふかきこころひとつに◎
    "M09_057",//ことのはにいてしうらみはつきはててこころにこむるうさになりぬる◎
    "M06_034",//いつまてかひとのつらさになからへむなほたまのをのおもひよわらて◎
    "M04_054",//いつかたにゆきかくれなむよのなかにみのあれはこそひともつらけれ◎
    "M02_014",//いかにせむつらきかきりをみてもまたなほしたはるるこころよはさを◎
    "M04_022",//つらくともなほうつせみのみをかへてのちのよまてやひとをこひまし◎
    "M06_151",//いくはくもいけらしいのちをこひつつそわれはいきつくひとにしらえす◎→除外次候補？
    "M02_079",//こひしとはたかなつけけむことならむしぬとそたたにいふへかりける◎
    "M06_005",//こひしなはうかれむたまよしはしたにわかおもふひとのつまにととまれ◎
    "M04_082",//なけかしなおもへはひとにつらかりしこのよなからのむくいなりけり◎
    
    //賀
    "GM03_077",//はつはるのはつねのけふのたまははきてにとるからにゆらくたまのを◎
    "M07_078",//さかきはをてにとりもちていのりつるかみのよよりもひさしからなむ◎
    "M07_091",//さかきはのかをかくはしみとめくれはやそうちひとそまとゐせりける◎
    "M03_017",//まきもくのあなしのやまのやまひととひともみるかにやまかつらせよ◎→*firefoxのみcompositeOperation= "lighter" が効かない（応急処置済）
    "M08_001",//おとたかきつつみのやまのうちはへてたのしきみよとなるそうれしき◎
    "M02_008",//くもりなきたまのうてなにのほりてそはるかなるよのこともみえける◎
    "M07_176",//かはのへのゆついはむらにくさむさずつねにもがもなとこをとめにて◎
    "M03_133",//いにしへにありきあらすはしらねともちとせのためしきみにはしめむ◎
    "M06_122",//ちとせまてかきれるまつもけふよりはきみにひかれてよろつよやへむ◎
    "M08_003",//しきしまややまとしまねもかみよよりきみかためとやかためおきけむ◎
    "M08_153",//しきしまのやまとのくににひとふたりありとしおもははなにかなけかむ◎
    "M03_016",//ひにみたひおろかなるみをかへりみてつかふるみちもわかきみのため◎
    "M03_071",//よつのうみなみもをさまるしるしとてみつのたからをみにそつたふる◎
    
    //"credit",
];

export default AllPagesURL;


//"M05_042",//おもふにはしのふることそまけにけるいろにはいてしとおもひしものを◎→要アニメーション改造

//"M07_092",//ねになきてわれこひしなはうつせみのむなしきからをあはれともみよ◎→除外
//"M02_130",//いのちやはなにそはつゆのあたものをあふにしかへはをしからなくに◎→除外
//"M02_010",//このかはゆふねはゆくへきありといへとわたりせことにまもるひとあり〇
//"M06_038",//たたにあひてみてはのみこそたまきはるいのちにむかふあかこひやまめ〇???
//"M05_033",//まつのねにかせのしらへをまかせてはたつたひめこそあきはひくらし◎???
//"M03_093",//けふまつるしるしにとてやそのかみはみかさとともにあまくたりけむ◎肥後
//"M03_040",//みのほとをしらすとひとやおもふらむかくうきなからとしをへぬれは◎藤原宗家
//"M04_079",//をりをりのうきみのほとはしりなからたたよのつねにむかふくるしさ◎正徹
//"GM02_169",//ものとしてみえさらめやはよのなかにいろをはなるるかたちなけれは◎進子内親王
//"M03_092",//さとひとのみるめはつかしさふるこにさとはすきみかみやてしりふり◎大伴家持
//"M02_055",//あひみてはむつましくこそなるときけつれなさのみもまさるきみかな〇???
