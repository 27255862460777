import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import TopAnm from "../../components/topAnm"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./1plus31_2.module.css"
import { openButtonDiv } from "./1plus31_2.module.css"
import { openButton } from "./1plus31_2.module.css"
import { thumNails } from "./1plus31_2.module.css"
import { thumNail } from "./1plus31_2.module.css"
import { curThumnail } from "./1plus31_2.module.css"
import { modalArea } from "./1plus31_2.module.css"
import { isShowCss } from "./1plus31_2.module.css"
import { modalBg } from "./1plus31_2.module.css"
import { modalWrapperWrapper } from "./1plus31_2.module.css"
import { modalWrapperScale } from "./1plus31_2.module.css"
import { modalWrapper } from "./1plus31_2.module.css"
import { modalContentCSS } from "./1plus31_2.module.css"
import { modalAnm } from "./1plus31_2.module.css"
import { modalSwipe } from "./1plus31_2.module.css"
import { close } from "./1plus31_2.module.css"
import { decription } from "./1plus31_2.module.css"


import getLanguage from "../../utilt/getLangueage"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

import AllPagesURL from "../../data/1plus31/AllPagesURL"
import AllWakaEn from "../../data/1plus31/AllWakaEn"
import AllWakaJp from "../../data/1plus31/AllWakaJp"


/**
 * ページ
 */
const Oneplus31_2 = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }

  let iFrame = useRef();

  useEffect(() => {
    setTimeout(() => {
      iFrame.current = document.getElementById('iFrame');
      //console.log(iFrame.current.src, iFrame.current);
    }, 50)
  }, [])


  //画像データ取得
  const data = useStaticQuery(graphql`query MyQueryAnimatedCartoons {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "animated_cartoons"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(
            quality: 50
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`)

  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const thumbNails = [];
  //2 ファイル名でソート(AllPageURLに準拠)
  imagesOld.forEach(a => {
    for (let i = 0; i < AllPagesURL.length; i++) {
      if (a.base.split(".")[0] === AllPagesURL[i]) {
        thumbNails[i] = a;
        thumbNails[i].index = i;
      }
    }
  })
  const ImagesLength = AllPagesURL.length;

  /**
     * モダール用
     */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);


  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
      if (index !== picNum && !isShow) {
        setIsShow(true);
        setTimeout(() => setIsShow(false), 50);
        setTimeout(() => setIsShow(true), 100);
        if (iFrame.current) {
          //iFrame.current.contentWindow.location.reload(true);
        }
      }
    }
    setIsShow(!isShow);
  }



  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }


  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    } else {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }
  }

  useEffect(() => {
    function plusPicNum() {
      let result = (picNum + 1) % ImagesLength;
      setPicNum(result);
    }

    function minusPicNum() {
      let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
      setPicNum(result);
    }

    //for touchscreen
    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }


    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, ImagesLength])


  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % ImagesLength;
        setPicNum(result);
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
        setPicNum(result);
      } else if (event.keyCode === 27) {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      document.getElementById(`pic${String(picNum)}`).focus();
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, isShow, ImagesLength])




  return <>
    <Seo
      title="1(+31) Index"
      description={lang !== "ja" ?
        "List view of 1(+31)" :
        "1(+31)の一覧表示"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_1plus31_2.jpg"
      pathname="/work/1plus31_2"
    />

    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1
          className={eachTitle}
          style={{
            letterSpacing: `${lang !== "ja" ? "0px" : "5px"}`
          }}
        >
          {lang !== "ja" ? "List view of 1(+31)" : "1(+31)一覧"}
        </h1>

        <div
          className={openButtonDiv}
        >
          <Link
            className={openButton}
            style={{
              letterSpacing: `${lang !== "ja" ? "0.5px" : "2.5px"}`
            }}
            to="../1plus31"
          >
            {lang !== "ja" ? "Back to 1(+31)" : "1(+31)に戻る"}
          </Link>
        </div>

        <div
          className={thumNails}
        >
          {thumbNails.map((node, index) => (
            <div
              className={isShow && picNum === node.index ? `${thumNail} ${curThumnail}` : thumNail}
              onClick={event => handleModal(event, node.index)}
              onKeyDown={event => handleModalKey(event, node.index)}
              role="button"
              tabIndex={0}
              key={node.base.split(".")[0]}
              title={index + 1 + ": " + (lang !== "ja" ? AllWakaEn[node.index] : AllWakaJp[node.index])}
              id={"pic" + String(node.index)}
            >
              <GatsbyImage
                image={node.childImageSharp.gatsbyImageData}
                alt={node.base.split(".")[0]} />
            </div>
          ))}
          <div className={thumNail}> </div>
          <div className={thumNail}> </div>
          <div className={thumNail}> </div>

        </div>
      </Article>
    </Layout>


    {/**
       * モーダル
      */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCss}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >

        <div
          className={
            isShow ? `${modalWrapperWrapper} ${modalWrapperScale}` : modalWrapperWrapper
          }
        >
          <div
            className={modalWrapper}
          >
            <div
              className={modalContentCSS}
              onClick={event => clickModal(event)}
              onKeyDown={() => { }}
              role="button"
              tabIndex={0}
              id="modalContent"
            >
              <TopAnm
                className={modalAnm}
                width={winSize.width}
                height={winSize.height}
                link={"https://www.drawinghell.com/animated_cartoons/" + AllPagesURL[picNum] + ".html"}
              />
              <span
                className={modalSwipe}
                onClick={event => clickModal(event)}
                onKeyDown={() => { }}
              >
              </span>
            </div>
          </div>
          <div
              className={decription}
            >
              {lang !== "ja" ? AllWakaEn[picNum] : AllWakaJp[picNum]}
            </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>
  </>;
}

export default Oneplus31_2
